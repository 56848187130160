import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, Title } from '@angular/platform-browser';
import { DpConfigService } from '@dp/services/config.service';
import { DOCUMENT, Location } from '@angular/common';
import { filter, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { SplashScreenService } from '@dp/services/splash-screen.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PolicyTypes } from 'app/documents/documents.model';
import { AuthService } from './auth/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { environment } from 'environments/environment';
import { GoogleAnalyticsService } from 'ngx-google-analytics';
import { ChatbotService } from './shared/components/chatbot/chatbot.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  dpConfig: any;
  navigation: any;
  PolicyTypes = PolicyTypes;
  private _unsubscribeAll: Subject<any>;
  title = 'CARGOES Flow';
  location: Location;

  constructor(
    @Inject(DOCUMENT) private document: any,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private dpConfigService: DpConfigService,
    private splashScreenService: SplashScreenService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    location: Location,
    private titleService: Title,
    public authService: AuthService,
    private dialog: MatDialog,
    private gaService: GoogleAnalyticsService,
    public chatbotService: ChatbotService
  ) {
    this.matIconRegistry.addSvgIcon('site_logo', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cargoflow-new-square.svg'));
    this.matIconRegistry.addSvgIcon('site_logo_long', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/cargoflow-new.svg'));
    this.matIconRegistry.addSvgIcon(
      'upload_containers',
      this.domSanitizer.bypassSecurityTrustResourceUrl('assets/images/svg/side-nav/polished/upload-containers.svg')
    );
    this.matIconRegistry.addSvgIcon('chevron_down', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/chevron_down.svg'));
    this.matIconRegistry.addSvgIcon('chevron_up', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/chevron_up.svg'));
    this.matIconRegistry.addSvgIcon('calendar', this.domSanitizer.bypassSecurityTrustResourceUrl('assets/svg/calendar1.svg'));

    this._unsubscribeAll = new Subject();
    this.location = location;
    window['dataLayer'] = window['dataLayer'] || [];
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd)
      )
      .subscribe((res: NavigationEnd) => {
        this.pushEventToDatalayer();
      });
  }

  //TODO: chrome 124 only.
  isBrowserInTruble(): boolean {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Edg') > -1) {
      return false;
    } else if (userAgent.indexOf('Chrome') > -1) {
      return navigator.userAgent.includes('Chrome/124');
    } else {
      return false;
    }
  }

  ngOnInit(): void {
    if (this.isBrowserInTruble()) {
      document.addEventListener('DOMNodeInserted', function () {
        const elements = document.querySelectorAll('[aria-owns]');
        elements.forEach((element) => {
          element.removeAttribute('aria-owns');
        });
      });
    }

    // Subscribe to config changes
    this.dpConfigService.config.pipe(takeUntil(this._unsubscribeAll)).subscribe((config) => {
      this.dpConfig = config;

      // Boxed
      if (this.dpConfig.layout.width === 'boxed') {
        this.document.body.classList.add('boxed');
      } else {
        this.document.body.classList.remove('boxed');
      }

      // Color theme - Use normal for loop for IE11 compatibility
      for (let i = 0; i < this.document.body.classList.length; i++) {
        const className = this.document.body.classList[i];

        if (className.startsWith('theme-')) {
          this.document.body.classList.remove(className);
        }
      }

      this.document.body.classList.add(this.dpConfig.colorTheme);
    });

    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        untilDestroyed(this),
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child.firstChild) {
            child = child.firstChild;
          }
          if (child.snapshot.data['title']) {
            return appTitle + ' - ' + child.snapshot.data['title'];
          }
          return appTitle;
        })
      )
      .subscribe((ttl: string) => {
        this.titleService.setTitle(ttl);
      });
    this.dialog.afterOpened.asObservable().subscribe((event) => {
      this.chatbotService.updateState({ showChatBox: false, showChatBubble: true });
    });
  }

  ngAfterViewInit() {
    // console.log('app after init: ', this.authService.currentUserValue);
    // if (this.authService.normalUserOnNormalPage() && this.authService.currentUserValue.userAcceptedTcs === false) {
    //   const config: MatDialogConfig = {
    //     height: '80%',
    //     width: '80%',
    //     closeOnNavigation: false,
    //     disableClose: true,
    //     hasBackdrop: true,
    //     backdropClass: 'backdropBackground',
    //   };
    //   const dialogRef = this.dialog.open(TcsDialogComponent, config);
    //   dialogRef.afterClosed().subscribe((dialogResult) => {
    //     if (dialogResult) {
    //       return true;
    //     }
    //   });
    // }
  }

  ngOnDestroy(): void {
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  openLink(para: string) {
    window.open(`/documents/${para}`, '_blank');
  }

  pushEventToDatalayer() {
    const currentUser = this.authService.currentUserValue;
    const state = currentUser?.userEmail?.toLowerCase().indexOf('dpworld.com') > 0 ? 'internal' : 'external';
    const companyName = currentUser?.organizationName;
    const userRole = currentUser?.accessRole;
    const accountType = currentUser?.accountType;
    const organizationId = currentUser?.organizationId;
    window['dataLayer'].push({
      event: environment.gaEvents.actions.userTracking,
      user_id: currentUser?.id,
      user_state: state,
      company_name: companyName,
      user_role: userRole,
      subscription_state: accountType,
      organisation_id: organizationId,
    });
  }
}
