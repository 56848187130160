export const USER_ACCESS = {
  TAG: 'tag',
  FLAG: 'flag',
  NOTES: 'notes',
  ADD_USER: 'addUser',
  EXPORT_DATA: 'exportData',
  MANAGE_USERS: 'manageUsers',
  LINK_TO_SHARE: 'linkToShare',
  POD_FILES: 'podFiles',
  ADD_SHIPMENT_TAB_VISIBILITY: 'addShipments:tab',
  ADD_SHIPMENT_ADD_SHIPMENT: 'addShipments:addShipments',
  ADD_SHIPMENT_UPLOAD_HISTORY: 'addShipments:uploadHistory',
  DEMURRAGE_CONFIG_AND_HOURS_BEFORE_ARRIVAL: 'notificationMetrics',
  SHIPMENT_UPDATE: 'shipments:update',
  SHIPMENT_DELETE: 'shipments:delete',
  MARK_AS_COMPLETE: 'markAsComplete',
  SHIPMENT_ADD_PRODUCT_OR_ORDER: 'shipments:addProductOrOrder',
  SHIPMENT_EDIT_PRODUCT_OR_ORDER: 'shipments:editProductOrOrder',
  SHIPMENT_REMOVE_PRODUCT_OR_ORDER: 'shipments:deleteProductOrOrder',
  SHIPMENT_MULTI_SELECT_BULK_ACTION: 'shipments:multiSelectBulkAction',
  SHIPMENT_REPORT_TRACKING_ERROR: 'shipments:reportTrackingError',
  SHIPMENT_SHARE_WITH_PARTNER: 'shipments:shareWithPartner',
  SHIPMENT_MARK_AS_COMPLETE: 'shipments:update',
  SHIPMENT_ADD_CARRIER_NAME: 'shipments:addCarrierName',
  DOCUMENTS_PREVIEW: 'documents:preview',
  DOCUMENTS_UPLOAD: 'documents:upload',
  DOCUMENTS_DOWNLOAD: 'documents:download',
  DOCUMENTS_DELETE: 'documents:delete',
  BASIC_ORDER_CREATE_NEW: 'ordersBasic:createOrder',
  BASIC_ORDER_EDIT: 'ordersBasic:editOrder',
  BASIC_ORDER_DELETE: 'ordersBasic:deleteOrder',
  ADVANCED_ORDER_ADD: 'ordersAdvanced:add',
  ADD_PRODUCT: 'addProduct',
  EDIT_PRODUCT: 'editProduct',
  REMOVE_PRODUCT: 'removeProduct',
  COMPANY_SETTING_COMPANY_PROFILE: 'companySetting:companyProfile',
  COMPANY_SETTING_ADD_PARTNERS: 'companySetting:addingPartners',
  COMPANY_SETTING_SHIPMENT_DOC: 'companySetting:shipmentdocuments',
  COMPANY_SETTING_SHIPMENT_CUSTOM_PARAM: 'companySetting:shipmentMetaInfoParameters',
  OCEAN_SHIPMENTS_MARK_AS_COMPLETE: 'oceanShipments:markAsComplete',
  ROAD_SHIPMENTS_MARK_AS_COMPLETE: 'roadShipments:markAsComplete',
  ROAD_SHIPMENTS_UPDATE_VEHICLE_DRIVER: 'roadShipments:updateVehicleAndDriverPhone',
  ADD_OCEAN_SHIPMENT: 'addShipments:oceanShipments',
  ADD_ROAD_SHIPMENT: 'addShipments:roadShipments',
  ADD_AIR_SHIPMENT: 'addShipments:airShipments',
  ADD_OL_SHIPMENT: 'addShipments:outsourcedLogisticsShipments',
  SHIPMENT_TAB_VISIBILITY: 'shipments:tab',
  SHIPMENT_OCEAN_PAGE: 'shipments:oceanShipments',
  SHIPMENT_ROAD_PAGE: 'shipments:roadShipments',
  SHIPMENT_AIR_PAGE: 'shipments:airShipments',
  SHIPMENT_OL_PAGE: 'shipments:outsourcedLogisticsShipments',
  DASHBOARD_TAB_VISIBILITY: 'dashboard:tab',
  DASHOBOARD_SUMMARY: 'dashboard:summary',
  DASHBOARD_OCEAN_SHIPMENT: 'dashboard:oceanShipments',
  DASHBOARD_ROAD_SHIPMENT: 'dashboard:roadShipments',
  DASHBOARD_AIR_SHIPMENT: 'dashboard:airShipments',
  CALENDAR_TAB_VISIBILITY: 'calendar:tab',
  CALENDAR_OCEAN_SHIPMENT: 'calendar:oceanShipments',
  CALENDAR_ROAD_SHIPMENT: 'calendar:roadShipments',
  CALENDAR_AIR_SHIPMENT: 'calendar:airShipments',
  SCHEDULES_TAB_VISIBILITY: 'schedules:tab',
  SCHEDULES_VESSEL: 'schedules:oceanShipments',
  SCHEDULES_AIR: 'schedules:airShipments',
  ANALYTICS_TAB_VISIBILITY: 'analytics:tab',
  ANALYTICS_OCEAN_SHIPMENT: 'analytics:oceanShipments',
  ANALYTICS_ROAD_SHIPMENT: 'analytics:roadShipments',
  ANALYTICS_AIR_SHIPMENT: 'analytics:airShipments',
  ORDERS_TAB_VISIBILITY: 'ordersBasic:tab',
  PRODUCT_MASTER_TAB_VISIBILITY: 'productMaster:tab',
  LOCATIONS_TAB_VISIBILITY: 'locations:tab',
  LOCATIONS_ADD_LOCATIONS: 'locations:addLocation',
  HELP_SUPPORT_CENTER: 'HelpAndSupport:support',
  NEWS_ARTICLES: 'newsArticles',
  INVOICES_TAB: 'invoices:tab'
};

export const ACCESS_TYPE = {
  VIEW: 'VIEW',
  HIDE: 'HIDE',
  ALL: 'ALL',
};

export const ROUTE_PATH_ACCESS = {
  '/upload-history?report=false': USER_ACCESS.ADD_SHIPMENT_UPLOAD_HISTORY,
  '/upload-history?report=true': USER_ACCESS.ADD_SHIPMENT_UPLOAD_HISTORY,
  '/upload-history': USER_ACCESS.ADD_SHIPMENT_UPLOAD_HISTORY,
  'add-shipments-one-by-one/:shipmentType': USER_ACCESS.ADD_SHIPMENT_ADD_SHIPMENT,
  '/users-teams/users': USER_ACCESS.MANAGE_USERS,
  '/users-teams/teams': USER_ACCESS.MANAGE_USERS,
  '/dashboard': USER_ACCESS.DASHBOARD_TAB_VISIBILITY,
  '/add-shipments/INTERMODAL_SHIPMENT': USER_ACCESS.ADD_OCEAN_SHIPMENT,
  '/add-shipments/AIR_SHIPMENT': USER_ACCESS.ADD_AIR_SHIPMENT,
  '/add-shipments/TRUCK_SHIPMENT': USER_ACCESS.ADD_ROAD_SHIPMENT,
  '/add-shipments/OL_SHIPMENT': USER_ACCESS.ADD_OL_SHIPMENT,
  '/ocean-shipments/tracking': USER_ACCESS.SHIPMENT_OCEAN_PAGE,
  '/road-shipments/tracking': USER_ACCESS.SHIPMENT_ROAD_PAGE,
  '/air-shipments/tracking': USER_ACCESS.SHIPMENT_AIR_PAGE,
  '/outsourced-logistics/tracking': USER_ACCESS.SHIPMENT_OL_PAGE,
  '/calendar/ocean-shipments': USER_ACCESS.CALENDAR_OCEAN_SHIPMENT,
  '/calendar/road-shipments': USER_ACCESS.CALENDAR_ROAD_SHIPMENT,
  '/calendar/air-shipments': USER_ACCESS.CALENDAR_AIR_SHIPMENT,
  '/performance/ocean-shipments': USER_ACCESS.ANALYTICS_OCEAN_SHIPMENT,
  '/performance/road-shipments': USER_ACCESS.ANALYTICS_ROAD_SHIPMENT,
  '/performance/air-shipments': USER_ACCESS.ANALYTICS_AIR_SHIPMENT,
  '/performance/benchmark': USER_ACCESS.ANALYTICS_OCEAN_SHIPMENT,
  '/orders': USER_ACCESS.ORDERS_TAB_VISIBILITY,
  '/help/feedback': USER_ACCESS.HELP_SUPPORT_CENTER,
  '/product-master': USER_ACCESS.PRODUCT_MASTER_TAB_VISIBILITY,
  '/invoices': USER_ACCESS.INVOICES_TAB
};
