import { commonEnvironment } from './environment.common';

//local development, change line 4 to setup your local env
let environmentType: 'local' | 'staging' | 'prod' | 'qa' = 'qa';
let localDevSetting = {};
if (window['envType'] && window['envType'] !== 'ENV_TYPE') {
  environmentType = window['envType'];
} else {
  localDevSetting = {
    baseUrl: 'http://localhost:4200/',
  };
}

const specialEnvironment = {
  local: {
    environmentType: 'local',
    enableBeta: true,
    useMock: false,
    production: false,
    productionHosting: false,
    ga: 'G-HJMWCHPQZL', //test site
    //ga: 'G-WT4L8VD6DP', //prod site
    rootUrl: 'http://localhost:3200/api/',
    feedbackServer: 'https://qa-feedback-service-api.cargoes.com/api/',
    clientId: 'FL',
    baseUrl: 'http://localhost:4200/',
    ssoAuthServer: 'https://dev-login.cargoes.com/',
    ssoApp: 'dtworld',
    apiValue: '859699906d5e7f76aa999b87980d41c9',
    feedbackApiValue: 'wlg9lTshHeXItOPSirQdMTKZTOp6k8vC',
    publicApiValue: '0BBufUaSZq98FC32Cep4ZbBNVTaUCtpj',
    apiValueVessel: 'ODDSqJPEWv4zFjj9fCfCYCOstwfqHHt9jJ19oQ5f',
    mapKey: 'AIzaSyDLnfi3i6G421HiGb5GBkd1FXRlPSCom30',
    bingMapkey: 'AirsKxxqaVyg_uxAfVQyiPgcfYzU_6XIMF9CBb_kNllpTfmhrdn_q65NuhekMl1s',
    recapchaKey: '6Ld0xfAUAAAAAEDEjVud1lFAivsQAMPeG0jFt5Qq',
  },
  staging: {
    environmentType: 'staging',
    enableBeta: false,
    useMock: false,
    production: true,
    productionHosting: false,
    rootUrl: 'https://staging-flow-api.cargoes.com/api/',
    feedbackServer: 'https://staging-feedback-service-api.cargoes.com/api/',
    ga: 'G-HJMWCHPQZL', //test site
    //ga: 'G-WT4L8VD6DP', //prod site
    clientId: 'FL',
    ssoAuthServer: 'https://staging-login.cargoes.com/',
    ssoApp: 'dtworld',
    baseUrl: 'https://staging-flow.cargoes.com/',
    apiValue: '14049e80317242e0d6644f82f6e758eb',
    feedbackApiValue: 'wlg9lTshHeXItOPSirQdMTKZTOp6k8vC',
    publicApiValue: '0BBufUaSZq98FC32Cep4ZbBNVTaUCtpj',
    apiValueVessel: 'ODDSqJPEWv4zFjj9fCfCYCOstwfqHHt9jJ19oQ5f',
    mapKey: 'AIzaSyDLnfi3i6G421HiGb5GBkd1FXRlPSCom30',
    bingMapkey: 'AirsKxxqaVyg_uxAfVQyiPgcfYzU_6XIMF9CBb_kNllpTfmhrdn_q65NuhekMl1s',
    recapchaKey: '6Ld0xfAUAAAAAEDEjVud1lFAivsQAMPeG0jFt5Qq',
    heartBeats: {
      containerDeliveryHeartBeat: 6, //every n second
      webUpdateHeartBeat: 6, //seconds
      notificationBeat: 30,
    },
  },
  qa: {
    environmentType: 'qa',
    enableBeta: true,
    useMock: false,
    production: true,
    productionHosting: false,
    rootUrl: 'https://qa-flow-api.cargoes.com/api/',
    feedbackServer: 'https://qa-feedback-service-api.cargoes.com/api/',
    ga: 'G-HJMWCHPQZL', //test site
    //ga: 'G-WT4L8VD6DP', //prod site
    clientId: 'FL',
    ssoAuthServer: 'https://dev-login.cargoes.com/',
    baseUrl: 'https://qa-flow.cargoes.com/',
    ssoApp: 'dtworld',
    apiValue: '14049e80317242e0d6644f82f6e758eb',
    feedbackApiValue: 'wlg9lTshHeXItOPSirQdMTKZTOp6k8vC',
    publicApiValue: '0BBufUaSZq98FC32Cep4ZbBNVTaUCtpj',
    apiValueVessel: 'ODDSqJPEWv4zFjj9fCfCYCOstwfqHHt9jJ19oQ5f',
    mapKey: 'AIzaSyDLnfi3i6G421HiGb5GBkd1FXRlPSCom30',
    bingMapkey: 'AirsKxxqaVyg_uxAfVQyiPgcfYzU_6XIMF9CBb_kNllpTfmhrdn_q65NuhekMl1s',
    recapchaKey: '6Ld0xfAUAAAAAEDEjVud1lFAivsQAMPeG0jFt5Qq',
    heartBeats: {
      containerDeliveryHeartBeat: 6, //every n second
      webUpdateHeartBeat: 6, //seconds
      notificationBeat: 30,
    },
  },
  prod: {
    environmentType: 'prod',
    enableBeta: false,
    useMock: false,
    production: true,
    productionHosting: true,
    rootUrl: 'https://flow-api.cargoes.com/api/',
    feedbackServer: 'https://feedback-service-api.cargoes.com/api/',
    ga: 'G-WT4L8VD6DP', //prod site
    clientId: 'FL',
    baseUrl: 'https://flow.cargoes.com/',
    ssoAuthServer: 'https://login.cargoes.com/',
    ssoApp: 'DPWorld',
    apiValue: '7cf0e70059e5bfb3c37171cc7cd229cc',
    feedbackApiValue: 'cyjRHsN7aDA6asOvwQeW0S5yIFpW4zF2',
    publicApiValue: 'dL6SngaHRXZfvzGA716lioRD7ZsRC9hs',
    apiValueVessel: 'ODDSqJPEWv4zFjj9fCfCYCOstwfqHHt9jJ19oQ5f',
    mapKey: 'AIzaSyAkEs40CRp76UFnU2xct8-PAzY5boSxES0',
    bingMapkey: 'AuESBelrecyRJw9mvd6vi_jokwGJ0XaRfsOtmYuZWtAqPZpZtzUgfTipH83IXRiF',
    recapchaKey: '6Ld0xfAUAAAAAEDEjVud1lFAivsQAMPeG0jFt5Qq',
    heartBeats: {
      containerDeliveryHeartBeat: 30, //every n second
      webUpdateHeartBeat: 6, //seconds
      notificationBeat: 30,
    },
  },
};

const SSOAuthRoutes = {
  SSORedirectUri: 'login',
  SSOLogoutUri: `auth/realms/${specialEnvironment[environmentType].ssoApp}/protocol/openid-connect/logout?post_logout_redirect_uri=`,
  SSOAuthUri: `auth/realms/${specialEnvironment[environmentType].ssoApp}/protocol/openid-connect/auth`,
};

export const environment = {
  ...commonEnvironment,
  ...specialEnvironment[environmentType],
  ...SSOAuthRoutes,
  ...localDevSetting,
};
