import { User } from 'app/auth/user.model';
import moment from 'moment';
import 'moment-timezone';
import * as CryptoJS from 'crypto-js';
//import 'moment-timezone';
// import { StaticDataService } from '@dp/services/static-data-remote.service';
import { StaticData } from '@dp/services/static-data.model';
import { TruckPage } from 'app/truck-shipments/trucks-models';
import { IOption } from '@dp/components/select-autocomplete/select-autocomplete.component';
import { AirShipmentDetail } from 'app/air-shipment/air-tracking/air-tracking.model';
import { ShipmentDetail } from 'app/shipments2/shipments2.model';
import { IOceanShipment } from 'app/ocean-shipments/ocean-shipments-models';
import { ColumnSetting } from 'app/shared/shared.model';
import { deepClone } from './deep-clone';
import { SHIPMENT_HUMIDITY_STATES, SHIPMENT_TEMPERATURE_STATES } from '@dp/types';
import { SensorTypes, SensorValueState } from '../types/dp-model';
import { SVGIcons } from '../../app/navigation/nav.model';
import { Injectable, Injector } from '@angular/core';
import { DecimalPipe } from '@angular/common';
import { environment } from 'environments/environment';
import { OceanShipmentDetail } from 'app/new-ocean-shipment/ocean-tracking/ocean-tracking.model';
import { MatTabGroup } from '@angular/material/tabs';
import { TeamUser } from 'app/settings';

// export enum PastInRange {
//   Current_Month = 'Current month', //new
//   Past_3_months = 'Past 3 months',
//   Past_6_months = 'Past 6 months',
//   Past_12_months = 'Past 12 months',
//   Custom_range = 'Past 3 months',
//   Last_calendar_year = 'Last calendar year', //Last Calendar year
// }

export interface pastDateRange {
  start: string; //format: YYYY-MM-DD
  end: string; //format: YYYY-MM-DD
  endAtToday: boolean;
}

export interface UserPreferences {
  [userCode: string]: { [page: string]: number };
}

@Injectable()
export class Utility {
  private static secret_key = 'e1af787a-4859-45e1-a910-9aca11dd48e1';
  public static setItemEncrypted(key, value, storageType?: 'local' | 'session') {
    const storage = storageType === 'session' ? sessionStorage : localStorage;
    try {
      const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(value), Utility.secret_key).toString();
      storage.setItem(key, encryptedData);
    } catch (error) {
      console.log('Failed to set values to local storage' + error);
    }
  }
  public static getItemDecrypted(key, storageType?: 'local' | 'session') {
    const storage = storageType === 'session' ? sessionStorage : localStorage;
    let decryptedData;
    try {
      const encryptedData = storage.getItem(key);
      if (encryptedData) {
        decryptedData = JSON.parse(CryptoJS.AES.decrypt(encryptedData, Utility.secret_key).toString(CryptoJS.enc.Utf8));
      }
    } catch (error) {
      console.log('Failed to retrieve value from local storage' + error);
    }
    return decryptedData;
  }

  public static setItem(key, value, storageType?: 'local' | 'session') {
    const storage = storageType === 'session' ? sessionStorage : localStorage;
    storage.setItem(key, JSON.stringify(value));
  }

  public static getItem(key, storageType?: 'local' | 'session') {
    const storage = storageType === 'session' ? sessionStorage : localStorage;
    const val = storage.getItem(key);
    return JSON.parse(val);
  }

  public static removeItem(key, storageType?: 'local' | 'session') {
    const storage = storageType === 'session' ? sessionStorage : localStorage;
    storage.removeItem(key);
  }

  public static getInitialsByFullName(fullName: string): string {
    if (fullName.indexOf(' ') === -1) return fullName.slice(0, 2);

    let names = fullName.split(' ');
    let initials = names[0].charAt(0) + names[names.length - 1].charAt(0);
    return initials.toUpperCase();
  }

  public static getInitials(user: User): string {
    if (!user || (!user.firstName && !user.lastName)) {
      return 'N/A';
    }
    let initials = '';
    if (user.firstName && user.firstName.length > 0) {
      initials += user.firstName.charAt(0);
    }
    if (user.lastName && user.lastName.length > 0) {
      initials += user.lastName.charAt(0);
    }
    return initials.toUpperCase();
  }

  public static getFullName(user: User | TeamUser): string {
    let fullName =
      (user.firstName ? user.firstName : '') + (user.middleName ? ' ' + user.middleName : '') + (user.lastName ? ' ' + user.lastName : '');
    if (!user.firstName && !user.lastName) {
      fullName = user.userEmail.split('@')[0];
    }
    return fullName;
  }

  public static getEnumKeyByEnumValue<T extends { [index: string]: string }>(myEnum: T, enumValue: string): keyof T | null {
    let keys = Object.keys(myEnum).filter((x) => myEnum[x] == enumValue);
    return keys.length > 0 ? keys[0] : null;
  }
  //get rid of json data property with null, undefined, empty
  public static cleanupData(o: any): Object {
    if (Object.prototype.toString.call(o) == '[object Array]') {
      for (let key = 0; key < o.length; key++) {
        this.cleanupData(o[key]);
        if (Object.prototype.toString.call(o[key]) == '[object Object]' || o[key] === '') {
          if (Object.keys(o[key]).length === 0) {
            o.splice(key, 1);
            key--;
          }
        }
      }
    } else if (Object.prototype.toString.call(o) == '[object Object]') {
      for (let key in o) {
        let value = this.cleanupData(o[key]);
        if (value === null || value === '' || value === undefined) {
          delete o[key];
        }
        if (Object.prototype.toString.call(o[key]) == '[object Object]') {
          if (Object.keys(o[key]).length === 0) {
            delete o[key];
          }
        }
        if (Object.prototype.toString.call(o[key]) == '[object Array]') {
          if (o[key].length === 0) {
            delete o[key];
          }
        }
      }
    }
    return o;
  }
  public static cleanupDataAndTrimSpace(o: any): Object {
    if (Object.prototype.toString.call(o) == '[object Array]') {
      for (let key = 0; key < o.length; key++) {
        this.cleanupData(o[key]);
        if (Object.prototype.toString.call(o[key]) == '[object Object]' || o[key] === '') {
          if (Object.keys(o[key]).length === 0) {
            o.splice(key, 1);
            key--;
          }
        }
      }
    } else if (Object.prototype.toString.call(o) == '[object Object]') {
      for (let key in o) {
        let value = this.cleanupData(o[key]);
        if (value === null || value === '' || value === undefined) {
          delete o[key];
        } else if (typeof value === 'string') {
          o[key] = (o[key] as string).trim();
        }
        if (Object.prototype.toString.call(o[key]) == '[object Object]') {
          if (Object.keys(o[key]).length === 0) {
            delete o[key];
          }
        }
        if (Object.prototype.toString.call(o[key]) == '[object Array]') {
          if (o[key].length === 0) {
            delete o[key];
          }
        }
      }
    }
    return o;
  }

  public static getPastDate(starting: string): moment.Moment {
    if (starting === 'ARRIVAL_LAST_3_MONTH' || starting === 'DEPART_LAST_3_MONTH') {
      return moment().startOf('month').subtract(2, 'months');
    } else if (starting === 'ARRIVAL_LAST_6_MONTH' || starting === 'DEPART_LAST_6_MONTH') {
      return moment().startOf('month').subtract(5, 'months');
    } else if (starting === 'ARRIVAL_LAST_12_MONTH' || starting === 'DEPART_LAST_12_MONTH') {
      return moment().startOf('month').subtract(11, 'months');
    } else if (starting === 'ARRIVAL_YEAR_TO_DATE' || starting === 'DEPART_YEAR_TO_DATE') {
      return moment().startOf('year');
    } else {
      return moment();
    }
  }

  public static getPastDateInRange(pastInRange: string): pastDateRange {
    let result: pastDateRange;
    let start, end: moment.Moment;
    let endAtToday = true;
    let today: string = undefined;
    let thisMonth = moment(today).month();
    let monthsPassedThisQuarter = thisMonth % 3;
    // console.log(`thisMonth=${thisMonth}, monthsPassedThisQuarter=${monthsPassedThisQuarter} `);
    switch (pastInRange) {
      case 'TODAY':
        start = moment();
        end = moment();
        endAtToday = true;
        break;
      case 'YESTERDAY':
        start = moment().subtract(1, 'days');
        end = start;
        endAtToday = false;
        break;
      case 'Last03Days':
        start = moment().subtract(3, 'days');
        end = moment();
        endAtToday = true;
        break;
      case 'Last07Days':
        start = moment().subtract(7, 'days');
        end = moment();
        endAtToday = true;
        break;
      case 'Last14Days':
        start = moment().subtract(14, 'days');
        end = moment();
        endAtToday = true;
        break;
      case 'Last28Days':
        start = moment().subtract(28, 'days');
        end = moment();
        endAtToday = true;
        break;
      case 'Next07Days':
        start = moment().add(1, 'days');
        end = moment().add(7, 'days');
        endAtToday = false;
        break;
      case 'Next14Days':
        start = moment().add(1, 'days');
        end = moment().add(14, 'days');
        endAtToday = false;
        break;
      case 'Next28Days':
        start = moment().add(1, 'days');
        end = moment().add(28, 'days');
        endAtToday = false;
        break;

      case 'ARRIVAL_CURRENT_MONTH':
      case 'DEPART_CURRENT_MONTH':
      case 'THIS_MONTH':
        start = moment().startOf('month');
        end = moment();
        endAtToday = true;
        break;
      case 'LAST_MONTH':
        start = moment().startOf('month').subtract(1, 'months');
        end = moment().startOf('month').subtract(1, 'months').endOf('month');
        endAtToday = true;
        break;
      case 'DEPART_LAST_3_MONTH':
      case 'ARRIVAL_LAST_3_MONTH':
        start = moment().startOf('month').subtract(3, 'months');
        end = moment();
        endAtToday = true;
        break;
      case 'THIS_QUARTER':
        start = moment().startOf('month').subtract(monthsPassedThisQuarter, 'months');
        end = moment();
        endAtToday = false;
        break;

      case 'LAST_QUARTER':
        start = moment()
          .startOf('month')
          .subtract(3 + monthsPassedThisQuarter, 'months');
        end = moment()
          .subtract(monthsPassedThisQuarter + 1, 'months')
          .endOf('month');
        endAtToday = false;
        break;
      case 'DEPART_LAST_6_MONTH':
      case 'ARRIVAL_LAST_6_MONTH':
        start = moment().startOf('month').subtract(6, 'months');
        end = moment(today);
        endAtToday = true;
        break;
      case 'DEPART_LAST_12_MONTH':
      case 'ARRIVAL_LAST_12_MONTH':
        start = moment().startOf('month').subtract(12, 'months');
        end = moment();
        endAtToday = true;
        break;
      case 'DEPART_YEAR_TO_DATE':
      case 'YEAR_TO_DATE':
      case 'ARRIVAL_YEAR_TO_DATE':
        start = moment().startOf('year');
        end = moment();
        endAtToday = true;
        break;
      case 'DEPART_LAST_CALENDAR_YEAR':
      case 'ARRIVAL_LAST_CALENDAR_YEAR':
        start = moment().subtract(1, 'years').startOf('year');
        end = moment().subtract(1, 'years').endOf('year');
        endAtToday = false;
        break;
      default:
        break;
    }
    return { start: start.format('YYYY-MM-DD'), end: end.format('YYYY-MM-DD'), endAtToday };
  }

  public static detectedTimezone(staticData: StaticData): string {
    if (!moment.tz) {
      return '';
    }

    let guess = moment.tz.guess();

    if (!staticData.timezones_v2.find((timezone) => timezone.code === guess)) {
      return '';
    }
    return guess;
  }

  public static detectedLocale(existingLocales: Object): string {
    return navigator.languages.find((item) => existingLocales[item]) || 'en-US';
  }

  /**
   * Add an item to a localStorage() object
   * @param {String} name  The localStorage() key
   * @param {String} key   The localStorage() value object key
   * @param {String} value The localStorage() value object value
   */
  public static addToLocalStorageObject = function (name, key, value) {
    // Get the existing data
    let data = Utility.getLocalStorageObject(name);

    // Add new data to localStorage Array
    data[key] = value;

    // Save back to localStorage
    localStorage.setItem(name, JSON.stringify(data));
  };

  public static getLocalStorageObject(name: string) {
    let existing = localStorage.getItem(name);

    // If no existing data, create json obj
    // Otherwise, convert the localStorage string to json obj
    let data = existing ? JSON.parse(existing) : {};
    return data;
  }

  public static getLocalStorageObjectValue(storageKey: string, objKey: string) {
    let dataString = localStorage.getItem(storageKey);
    let data = dataString ? JSON.parse(dataString) : {};

    return data?.[objKey];
  }

  /// check two objects has same keys
  public static hasSameKeys(o1: Object, o2: Object) {
    const keys1 = Object.keys(o1).sort();
    const keys2 = Object.keys(o2).sort();
    return JSON.stringify(keys1) === JSON.stringify(keys2);
  }

  //TODO: unit test
  //reference: https://stackoverflow.com/questions/32911630/how-do-i-deal-with-localstorage-in-jest-tests
  public static removeFromLocalStorageObject = function (name, key) {
    // Get the existing data
    let existing = localStorage.getItem(name);

    // If no existing data, create an array
    // Otherwise, convert the localStorage string to an array
    let data = existing ? JSON.parse(existing) : {};

    if (!data) return;

    // Add new data to localStorage Array
    delete data[key];

    // Save back to localStorage
    localStorage.setItem(name, JSON.stringify(data));
  };

  public static getPanelWidth(
    panelName:
      | 'oceanSidePanelWidth'
      | 'truckSidePanelWidth'
      | 'airSidePanelWidth'
      | 'olSidePanelWidth'
      | 'orderAdvancedSidePanelWidth'
      | 'demurrageSidePanelWidth'
  ): number {
    return Utility.getLocalStorageObjectValue('settings', panelName) || '60%';
  }
  public static setPanelWidth(
    panelName:
      | 'oceanSidePanelWidth'
      | 'truckSidePanelWidth'
      | 'airSidePanelWidth'
      | 'olSidePanelWidth'
      | 'orderAdvancedSidePanelWidth'
      | 'demurrageSidePanelWidth',
    panelWidth: number
  ) {
    Utility.addToLocalStorageObject('settings', panelName, panelWidth);
    // let settings = localStorage.getItem('settings') || {
    //   oceanSidePanelWidth: 750,
    //   truckSidePanelWidth: 650,
    //   airSidePanelWidth: 750,
    // };
    // settings[panelName] = panelWidth;
    // localStorage.setItem('settings', JSON.stringify(settings));
  }

  public static getUTCFromTimezone(localDate, timezone): string {
    return moment.tz(localDate, timezone).utc(false).toISOString();
  }

  // public static getTemperatureSvg(temp: number, temperatureInfo: TruckPage.TemperatureInfo): string {
  //   let SVGs = ['assets/svg/temp-cold.svg', 'assets/svg/temp-ok.svg', 'assets/svg/temp-hot.svg'];
  //   return temp >= +temperatureInfo.maxTolerantTemperature ? SVGs[2] : temp <= +temperatureInfo.minTolerantTemperature ? SVGs[0] : SVGs[1];
  // }

  public static getSensorSvg(type?: SensorTypes, state?: SensorValueState) {
    if (!type && !state) {
      return 'assets/svg/sensor.svg';
    }
    let SVGIcons = {
      temperature: {
        HIGH: 'assets/svg/temp-high.svg',
        NORMAL: 'assets/svg/temp-ok.svg',
        LOW: 'assets/svg/temp-low.svg',
      },
      humidity: {
        HIGH: 'assets/svg/humidity-high.svg',
        NORMAL: 'assets/svg/humidity-ok.svg',
        LOW: 'assets/svg/humidity-low.svg',
      },
    };
    return SVGIcons[type]?.[state];
  }
  public static getTemperatureSvg(state: SHIPMENT_TEMPERATURE_STATES): string {
    // let SVGs = ['assets/svg/temp-cold.svg', 'assets/svg/temp-ok.svg', 'assets/svg/temp-hot.svg'];
    let SVGs = ['assets/uds_icons/tempCold.svg', 'assets/uds_icons/tempNormal.svg', 'assets/uds_icons/tempHot.svg'];

    let result = '';
    switch (state) {
      case SHIPMENT_TEMPERATURE_STATES.LOW:
        result = SVGs[0];
        break;
      case SHIPMENT_TEMPERATURE_STATES.NORMAL:
        result = SVGs[1];
        break;
      case SHIPMENT_TEMPERATURE_STATES.HIGH:
        result = SVGs[2];
        break;

      default:
        break;
    }
    return result;
  }
  public static getHumiditySvg(state: SHIPMENT_HUMIDITY_STATES): string {
    // let SVGs = ['assets/svg/temp-cold.svg', 'assets/svg/temp-ok.svg', 'assets/svg/temp-hot.svg'];
    let SVGs = ['assets/uds_icons/tempCold.svg', 'assets/uds_icons/tempNormal.svg', 'assets/uds_icons/tempHot.svg'];

    let result = '';
    switch (state) {
      case SHIPMENT_HUMIDITY_STATES.LOW:
        result = SVGs[0];
        break;
      case SHIPMENT_HUMIDITY_STATES.NORMAL:
        result = SVGs[1];
        break;
      case SHIPMENT_HUMIDITY_STATES.HIGH:
        result = SVGs[2];
        break;

      default:
        break;
    }
    return result;
  }

  public static getSensorText(value: number, uom: string, type: SensorTypes): string {
    // const injector = Injector.create({ providers: [{ provide: DecimalPipe, useClass: DecimalPipe }] });
    // const decimalPipe = injector.get(DecimalPipe);
    // value = decimalPipe.transform(value, '1.1-1');
    return type === SensorTypes.temperature ? `${value > 0 ? '+' : ''}${value} ${uom ? uom : ''}` : `${value} ${uom ? uom : ''}`;
  }
  public static getTemperatureText(temp: number, uom): string {
    return `${temp > 0 ? '+' : ''}${temp} ${uom === '°F' || uom === 'F' ? '°C' : '°F'}`;
  }

  public static getHumidityText(humid: number, uom): string {
    return `${humid}${uom}`;
  }

  public static getRandomNumber(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
  }

  public static checkAllPropertiesAreEmpty(obj: Object) {
    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        if (obj[key] !== null && obj[key] !== undefined && obj[key] !== '') {
          return false;
        }
      }
    }
    return true;
  }

  public static numToWord(num: number): string {
    let words = [
      'zero',
      'one',
      'two',
      'three',
      'four',
      'five',
      'six',
      'seven',
      'eight',
      'nine',
      'ten',
      'eleven',
      'twelve',
      'thirteen',
      'fourteen',
      'fifteen',
      'sixteen',
      'seventeen',
      'eighteen',
      'nineteen',
    ];
    return words[num];
  }

  // public static getLast6Month():moment.Moment {
  // }
  // public static getLast3Month():moment.Moment {
  //   return ;
  // }
  // public static getLast12Month():moment.Moment {
  //   return moment().startOf('month').subtract(11, 'months');
  // }

  // public static getYearToDate():moment.Moment {
  //   return moment().startOf('year');
  // }

  //'2020-08-18T23:46:33.696Z' => '18/08/2020 16:46:33'
  // public static isoStringToLocalDateTimeString(isoString: string): string {
  //   return moment(isoString).format('L') + ' ' + moment(isoString).format('LTS');
  // }
  // public static isoStringToLocalTimeString(isoString: string): string {
  //   return moment(isoString).format('LT');
  // }

  public static removeHttp(url) {
    return url.replace(/^https?:\/\//, '');
  }

  public static getVesselNameNumber(element, isPlus = false) {
    let { vesselName: name, vesselImo: imo } = element;
    if (!isPlus) {
      return name && imo ? `${name} / ${imo}` : name ? `${name}` : '';
    }
    name = element.transportJourneys?.portToPort?.transportName;
    imo = element.transportJourneys?.portToPort?.tripNumber;
    return name && imo ? `${name} / ${imo}` : name ? `${name}` : imo ? `${imo}` : '';
  }

  // Creates an object composed of the object properties predicate returns truthy for
  public static pickBy(object, skip) {
    const obj = {};
    for (const key in object) {
      if (object[key] != skip) {
        obj[key] = object[key];
      }
    }
    return obj;
  }

  /* 
    keysMap = { name: 'userName', job: 'passion'}
    obj = { name: 'mike', job: 'gammer', foo: 'bar'}
    result: { userName: 'mike', passion: 'gammer', foo: 'bar'}
  */
  public static jsonKeyMapping(keysMap: object, obj: object): object {
    return Object.keys(obj).reduce((acc, key) => {
      const renamedObject = {
        [keysMap[key] || key]: obj[key],
      };

      return {
        ...acc,
        ...renamedObject,
      };
    }, {});
  }
  public static jsonSubKeyMapping(k1: string, k2: string, subKeysMap: object, obj: object): object {
    const cur = obj[k1]?.[k2];
    if (cur) {
      Object.keys(subKeysMap).map((key) => {
        const newKey = subKeysMap[key];
        obj[newKey] = cur[key];
      });
    }
    return obj;
  }

  public static modifyOptions(original: string[]): IOption[] {
    return original?.map((item) => {
      return {
        display: item,
        value: item,
      };
    });
  }
  public static modifyOptionsIdName(original: any[], displayKey, valueKey): IOption[] {
    return original?.map((item) => {
      return {
        display: item[displayKey],
        value: item[valueKey],
      };
    });
  }
  public static buildSubStatusArray(subStatus: Object) {
    let result = [];
    for (let key in subStatus) {
      let item = {
        key: null,
        text: key, //displayed text
      };
      result.push(item);
      // subStatus[key] is also an Object
      for (let s in subStatus[key]) {
        let item = {
          key: key + '.' + s,
          text: s,
        };
        result.push(item);
      }
    }

    return result;
  }

  //TODO: use this function for whole site
  public static transformFilter(filter: object): any {
    for (let key in filter) {
      if (key === 'q' && filter[key]) {
        filter[key] = filter[key].replace(/ /g, '|');
      } else if (Number.isInteger(filter[key])) {
        continue;
      }
      if (!filter[key] || Utility.isEmpty(filter[key])) {
        delete filter[key];
      } else if (Array.isArray(filter[key])) {
        // transform Array to string, separated by |
        filter[key] = filter[key].join('|');
      }
    }
    return filter;
  }

  public static daysToSeconds(days) {
    if (typeof days === 'number') return days * 24 * 60 * 60;
    return days;
  }

  public static secondsToDays(seconds) {
    if (typeof seconds === 'number') return Math.floor(seconds / (3600 * 24));
    return seconds;
  }

  public static getSharedOrgsInfo(shipment: AirShipmentDetail | ShipmentDetail | IOceanShipment | OceanShipmentDetail) {
    const partners = shipment?.shipmentPartners;
    let res = partners?.reduce((accu, cur) => {
      accu += (accu ? '\n' : '') + cur.organizationName;
      return accu;
    }, '');
    if (!res) {
      res = 'This shipment is not shared yet.';
    }
    return res;
  }

  public static transformColumnSettings(
    settings: ColumnSetting[],
    columnMapping,
    from: 'remote' | 'local',
    to: 'remote' | 'local'
  ): ColumnSetting[] {
    let res = deepClone(settings);
    Object.keys(columnMapping).map((key) => {
      const mappings = columnMapping[key];
      let columns = res?.find((setting) => setting.key === key).columns;
      columns = columns?.map((column) => {
        let mappingColumn = mappings.find((mapping) => mapping[from] === column.key);
        if (mappingColumn) {
          column.key = mappingColumn[to];
        }
        return column;
      });
    });
    return res;
  }

  public static generateAndTrackEventData(
    event: string,
    eventCategory: string,
    eventAction: string,
    eventLabel?: string,
    otherInfo?: object
  ) {
    const dataLaterEvent = {
      event,
      eventDetail: {
        eventCategory,
        eventAction,
        ...(eventLabel ? { eventLabel } : null),
      },
      ...(otherInfo || null),
    };
    this.trackEvent(dataLaterEvent);
  }

  public static trackEvent(dataLayerEvent: Object) {
    window['dataLayer'] = window['dataLayer'] || [];
    window['dataLayer'].push(dataLayerEvent);
  }
  public static getDefaultUOM(sensorType: SensorTypes): string {
    let uom: string;
    switch (sensorType) {
      case SensorTypes.temperature:
      case SensorTypes.product_temperature:
        uom = '°C';
        break;
      case SensorTypes.humidity:
        uom = '%Rh';
        break;
      case SensorTypes.light:
        uom = 'Qv';
        break;
      case SensorTypes.shock:
        uom = 'g';
        break;

      case SensorTypes.location:
        uom = '°';
        break;

      default:
        uom = `warning: ${sensorType} UOM is not setup yet!`;
        break;
    }
    return uom;
  }

  public static isEmptyOrWhiteSpace(str: string): boolean {
    return str === null || str?.match(environment.regex.emptyStringValidation) !== null;
  }

  public static isDateAfter24Hours(dateString?: string): boolean {
    try {
      if (!dateString) return false;
      const currentDate = new Date();
      const date = new Date(dateString);
      const timeDifference = currentDate.getTime() - date.getTime();
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      return hoursDifference > 24 ? true : false;
    } catch (error) {
      console.error(`Error parsing date string: ${error}`);
      return false;
    }
  }

  public static getQ(q: string): string {
    return q?.replace('&', ' ').toLowerCase();
  }

  public static redirectTo(path: string, replace?: boolean) {
    if (typeof window !== 'undefined') {
      const url = `${environment.baseUrl}${path}`;
      if (replace) {
        window.location.replace(url);
        return;
      }
      window.location.href = url;
    }
  }

  // capitalize the first letter of a string
  public static capitalize(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  // capitalize the first letter of a string
  public static capitalizeFirstLetter(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  // capitalize the first letter of each word of string and small case of rest letters
  public static capitalizeEachWord(str: string): string {
    const words = str.split(' ');

    for (let i = 0; i < words.length; i++) {
      words[i] = this.capitalizeFirstLetter(words[i]);
    }
    return words.join(' ');
  }

  public static isEmpty(value): boolean {
    return (
      value == null ||
      (typeof value === 'object' && Object.keys(value).length === 0) ||
      (typeof value === 'string' && value.trim().length === 0)
    );
  }
  public static buildQueryString(q: string, shipmentNumber: string) {
    //build query string by combining q and shipmentNumber
    return q && shipmentNumber ? q + '&' + shipmentNumber : shipmentNumber ? shipmentNumber : q ? q : '';
  }
  public static setActiveTabByLabel(tabGroup: MatTabGroup, label: string) {
    const tabs = tabGroup._tabs.toArray(); // Get an array of MatTab components

    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].textLabel === label) {
        tabGroup.selectedIndex = i; // Set the selectedIndex to the index of the tab with the desired label
        break;
      }
    }
  }
  public static getActiveTabIndexByLabel(tabGroup: MatTabGroup, label: string) {
    if (!tabGroup) {
      return 0;
    }
    const tabs = tabGroup._tabs.toArray(); // Get an array of MatTab components
    let selectedIndex = 0;
    for (let i = 0; i < tabs.length; i++) {
      if (tabs[i].textLabel === label) {
        selectedIndex = i; // Set the selectedIndex to the index of the tab with the desired label
        break;
      }
    }
    return selectedIndex;
  }
  /// dev helper function
  public static logTime(): string {
    const currentTime = new Date();
    const hours = currentTime.getHours().toString().padStart(2, '0');
    const minutes = currentTime.getMinutes().toString().padStart(2, '0');
    const seconds = currentTime.getSeconds().toString().padStart(2, '0');
    const milliseconds = currentTime.getMilliseconds().toString().padStart(2, '0');
    return `${hours}:${minutes}:${seconds}:${milliseconds}`;
  }
  // enum helper
  // const MyEnum = {
  //   Option1: 'Option 1',
  //   Option2: 'Option 2',
  //   Option3: 'Option 3',
  // };
  // key = Utility.getKeyByValue(MyEnum, 'Option 1'); // 'Option1'
  // console.log(MyEnum[key] === MyEnum.Option1) // true
  // public static getEnumKeyByValue(enumName, value) {
  //   return Object.keys(enumName).find((key) => enumName[key] === value);
  // }

  // User Preference
  public static saveOrUpdateUserPreference(userCode: string, page: string, size: number, timeStamp: number) {
    const userPreferences: UserPreferences = this.getUserPreferences();
    if (!userPreferences[userCode]) {
      userPreferences[userCode] = {};
    }
    userPreferences[userCode][page] = size;
    this.saveDataToLocalStorage(userPreferences);
  }

  public static findUserPreferenceValue(userCode: string, pageToFind: string): number | null {
    const userPreferences: UserPreferences = this.getUserPreferences();
    if (userPreferences[userCode] && userPreferences[userCode][pageToFind]) {
      return userPreferences[userCode][pageToFind];
    }
    return null; // Page not found
  }

  public static getUserPreferences(): UserPreferences {
    const storedData = JSON.parse(localStorage.getItem('user_pagination_preference')) || {};
    return storedData;
  }

  public static saveDataToLocalStorage(data: UserPreferences) {
    localStorage.setItem('user_pagination_preference', JSON.stringify(data));
  }

  public static convertToMoment(dateString: string, format: string): moment.Moment | null {
    return dateString ? moment(dateString, format) : null;
  }
}

export const onboardStorageHandler = (storageKey: string, status: string) => {
  Utility.setItem(storageKey, { [status]: true }, 'session');
  const storedData = Utility.getItem(storageKey);
  Utility.setItem(storageKey, {
    ...storedData,
    [status]: (storedData?.[status] || 0) + 1,
  });
};

export const runChatbotTour = (key: string, count: number): boolean => {
  const sessionData = Utility.getItem(key, 'session');
  const storedData = Utility.getItem(key);
  if (sessionData?.['skipped'] || sessionData?.['finished'] || storedData?.['skipped'] >= count || storedData?.['finished'] >= count) {
    return false;
  }
  return true;
};
