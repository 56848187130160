import { Component, OnInit, Input } from '@angular/core';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { dpAnimations } from '@dp/animations';
import { EntityType } from '@dp/types';
import { SvgMap } from 'app/shared/components/svg/uds-svg-map';
import { ShipmentMap } from 'app/shipments2/shipments2.model';

@Component({
  selector: 'dp-tracking-result-air',
  templateUrl: './_tracking-result-air.component.html',
  styleUrls: ['./_tracking-result-air.component.scss'],
  animations: dpAnimations,
})
export class TrackingResultAirComponent implements OnInit {
  SvgMap = SvgMap;
  EntityType = EntityType;
  showReportError = false;
  @Input() payload;
  @Input() data; // main data
  @Input() isBusy = false;
  @Input() errorText = '';
  @Input() tabs: string[] = ['Tracking'];
  @Input() tokenId: string;
  @Input() showDocumentsInSharedLinks = false;
  shipmentMap: ShipmentMap;
  transportJourneys: any;
  segmentDataTable: any;
  // shipmentTest = shipmentTest;
  exceptionCount = 0;
  @Input() showException = true;

  ngOnInit(): void {
    const data = this.payload;
    if (data) {
      if (data.mapsData) {
        this.shipmentMap = data.mapsData;
      }
      if (data.transportJourneys) {
        this.transportJourneys = data.transportJourneys;
      }

      if (Array.isArray(data.transportJourneys?.portToPort?.segmentData)) {
        // build segmentTable
        this.segmentDataTable = this.buildShippingSegmentTable(data.transportJourneys.portToPort.segmentData);
      }

      if (this.payload.error) {
        this.errorText = this.payload.error;
      }

      if (this.payload.exceptions?.steps) {
        this.payload.exceptions.steps.forEach((step) => {
          this.exceptionCount += step.exceptions.length;
        });
      }
    }
  }

  buildShippingSegmentTable(segmentData) {
    if (segmentData.length) {
      return {
        header: ['From', 'To', 'Transport Name', 'Trip #', 'Departure', 'Arrival'],
        rows: this.getOceanLineTableRows(segmentData),
      };
    }
    return null;
  }

  getOceanLineTableRows(segmentData: any[]) {
    return segmentData.map((data) => {
      return [
        {
          data: data.origin,
          className: '',
        },
        {
          data: data.destination,
          className: '',
        },
        {
          data: data.transportName,
          className: '',
        },
        {
          data: data.tripNumber,
          className: '',
        },
        {
          data: data.atd || data.etd,
          className: data.atd ? 'Actl.' : 'Estd.',
        },
        {
          data: data.ata || data.eta,
          className: data.ata ? 'Actl.' : 'Estd.',
        },
      ];
    });
  }

  isEmpty(exception): boolean {
    return !exception || Object.keys(exception).length === 0;
  }

  reportError() {
    this.showReportError = true;
  }

  close() {
    this.showReportError = false;
  }

  selectedTabIndex = 0;
  selectedTabChange(event: MatTabChangeEvent) {
    this.selectedTabIndex = event.index;
  }
}
