<div *ngIf="errorText" class="mt-12">
  <mat-error>{{errorText}}</mat-error>
</div>

<section class="tracking-info p-32">
  <div class="mt-12" *ngIf="payload" class="relative">
    <dp-container-details [container]="payload"></dp-container-details>
    <dp-svg *ngIf="!showReportError" class="reportIcon" tooltip="Report error" [src]="SvgMap.report" w="20"
      (click)="reportError()"></dp-svg>
  </div>

  <div *ngIf="!showReportError" @slideInLeft>
    <mat-tab-group style="overflow-y: auto;" (selectedTabChange)="selectedTabChange($event)">

      <!-- Tracking Tab -->
      <mat-tab [label]="tabs[0]">
        <section class="tab-content">

          <div *ngIf="transportJourneys">
            <h3>Tracking Details</h3>
            <dp-tracking-details [isManualIntermodalLinkage]="payload.isManualIntermodalLinkage" [transportJourneys]="transportJourneys" [shipment]="payload"></dp-tracking-details>
          </div>

          <ng-container *ngIf="transportJourneys?.shipToPort?.segmentData; let segmentData">
            <dp-shipment-segment [isIntermodalRoad]="true" [driverPhone]="transportJourneys?.shipToPort?.driverPhone" [segmentData]="segmentData" [context]='getPredictedEtaContext()'
              heading="Shipping Segments - FIRST MILE"></dp-shipment-segment>
          </ng-container>
          <ng-container *ngIf="transportJourneys?.portToPort?.segmentData; let segmentData">
            <dp-shipment-segment [isManualIntermodalLinkage]="payload.isManualIntermodalLinkage" [segmentData]="segmentData" [context]='getPredictedEtaContext()'
              heading="Shipping Segments"></dp-shipment-segment>
          </ng-container>
          <ng-container *ngIf="transportJourneys?.shipToDestination?.segmentData; let segmentData">
            <dp-shipment-segment [isIntermodalRoad]="true" [driverPhone]="transportJourneys?.shipToDestination?.driverPhone" [segmentData]="segmentData" [context]='getPredictedEtaContext()'
              heading="Shipping Segments - LAST MILE"></dp-shipment-segment>
          </ng-container>

          <ng-container *ngIf="shipmentMap">
            <h3>Tracking Events</h3>
            <dp-shipment-map [isManualIntermodalLinkage]="payload.isManualIntermodalLinkage" [shipmentDetail]="shipmentMap" [maxZoom]="12"></dp-shipment-map>
          </ng-container>

          <div *ngIf="transportJourneys?.portToPort?.statusSteps?.steps; let steps" class="mt-12 pl-12">
            <dp-tracking-step [steps]="steps" [context]='getPredictedEtaContext()'></dp-tracking-step>
          </div>
        </section>
      </mat-tab>

      <!-- Documents Tab -->
      <mat-tab *ngIf="showDocumentsInSharedLinks">
        <ng-template mat-tab-label>
          Documents
          <ng-container *ngIf="payload?.documents?.length !== 0">
            <div [ngClass]="{'active': selectedTabIndex === 1, 'miniFab' : payload?.documents?.length}">
              {{ payload?.documents?.length }}</div>
          </ng-container>
        </ng-template>


        <section class="tab-content">

          <!-- No document cases -->
          <div *ngIf="payload.documents.length == 0" fxLayout="column" fxLayoutAlign="center center">
            <h2 class="text-muted">No documents available!</h2>
            <!-- <img src="assets/images/svg/no-result-found.svg" /> -->
          </div>

          <ng-container *ngIf="payload.documents.length > 0">
            <dp-documents-upload-card-public *ngFor="let document of payload.documents" [document]="document"
              [entityType]="EntityType.TRACKING_SHIPMENT" [tokenId]="tokenId">
            </dp-documents-upload-card-public>
          </ng-container>

        </section>
      </mat-tab>

      <mat-tab *ngIf="showException && !isEmpty(payload.exceptions)">
        <ng-template mat-tab-label>
          Exceptions
          <div *ngIf="exceptionCount" class="miniFab" [ngClass]="{'active': selectedTabIndex === 2}">
            {{ exceptionCount }}</div>
        </ng-template>
        <section class="tab-content mt-8">
          <dp-os-exception [shipmentDetail]="payload" [steps]="payload.exceptions.steps"></dp-os-exception>
        </section>
      </mat-tab>

    </mat-tab-group>
  </div>

  <dp-report-error *ngIf="showReportError" [isEmail]="true" @slideInRight (goBack)="close()" (close)="close()" [data]="payload"
    [commentOnly]="true">
  </dp-report-error>
</section>