<div id="sidenav-list">

  <div class="nav-item add-nav-item" *ngIf='orgType !== ACCOUNT_TYPES.PLUS && showNavItem("add_shipments")'
    [tooltip]="isExpanded ? null : 'Add Shipments'" placement="right"
    [class]="userAccessService.accessClass(accessKeys.ADD_SHIPMENT_TAB_VISIBILITY)" tooltipClass="themeTooltip">
    <a [routerLink]="[]" (click)="showContextMenu($event)" class="div-centered add">
      <span *ngIf="!isExpanded" [setSVGAttributes]="svgAttributes" [inlineSVG]="'assets/uds_icons/add-2.svg'"
        class="add-non-expanded sideNavIcons">
      </span>
      <ng-container *ngIf="isExpanded">
        <span [setSVGAttributes]="svgAttributes" [inlineSVG]="'assets/uds_icons/add-2.svg'" class="sideNavIcons expanded-span"></span>
        <span *ngIf="isExpanded" class="ml-4 text-uppercase text-bold text">Add Shipments</span>
      </ng-container>
    </a>
  </div>
  <mat-nav-list #navList id="navList" class="mb-60" [class.expanded]="isExpanded">

    <section *ngIf='isExpanded; else nonExpanded'>
      <div *ngFor="let item of navItemsList" #itemList>
        <mat-expansion-panel *ngIf="showMenu(item.navItem) && showNavItem(item.navItem.labelKey)" [class]="userAccessService.accessClass(item.navItem.accessKey)"
          [disabled]='!isExpanded || !item.children?.length' [hideToggle]='!isExpanded || !item.children?.length' expanded='true'
          [ngClass]="{ 'selected-item': isSelected(item) }">
          <mat-expansion-panel-header class='unexpanded-child'>
            <mat-panel-title class="nav-item">
              <a (click)="onMenuItemClick(item, $event)" class="link">
                <ng-container *ngIf="isExpanded">
                  <span [setSVGAttributes]="svgAttributes" *ngIf="item.navItem.svg" [inlineSVG]="item.navItem.svg"
                    class="sideNavIcons expanded-span"></span>
                  <mat-icon *ngIf="item.navItem.matIcon" mat-list-icon class="sideNavIcons">{{
                    item.navItem.matIcon }}</mat-icon>
                  <span *ngIf="isExpanded" class="ml-4 text-uppercase text-bold text">{{ item.navItem.label }}</span>
                </ng-container>
              </a>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div *ngIf='isExpanded'>
            <ng-container class="nav-item expanded-child" *ngFor="let childItem of item.children">
              <div class="nav-item expanded-child" [class]="userAccessService.accessClass(childItem.navItem.accessKey)" *ngIf="showMenu(childItem.navItem) && showNavItem(childItem.navItem.labelKey)">
                <a (click)="onMenuItemClick(childItem, $event)"
                  [ngClass]="{ 'active-link': childItem.navItem.label=== SideNavRouteVsLabel[this.currentRouteURL] }"
                  class="link child-container">
                  <ng-container *ngIf="isExpanded">
                    <span [setSVGAttributes]="getSvgAttributes(childItem.navItem)" *ngIf="childItem.navItem.svg"
                      [inlineSVG]="childItem.navItem.svg" class="sideNavIcons child expanded-span pl-40"></span>
                    <mat-icon *ngIf="childItem.navItem.matIcon" mat-list-icon class="sideNavIcons child">{{
                      childItem.navItem.matIcon }}</mat-icon>
                    <span *ngIf="isExpanded" class="ml-4 text-uppercase text-bold text child-expand"
                      [class]="childItem.navItem.uri === '/outsourced-logistics/tracking' ? 'ol-text' : 'ml-4'">{{
                      childItem.navItem.label }}</span>
                  </ng-container>
                </a>
              </div>
            </ng-container>
          </div>
        </mat-expansion-panel>

        <mat-divider *ngIf="!item.navItem" class="line"></mat-divider>
      </div>
    </section>
    <ng-template #nonExpanded>
      <ng-container *ngFor="let item of navItemsList">
        <div class="nav-item non-expanded" tooltip="{{ item.navItem.label }}" placement="right" [class]="userAccessService.accessClass(item.navItem.accessKey)"
          [ngClass]="{ 'selected-item': isSubMenuOpen(item)}" tooltipClass="themeTooltip" *ngIf="showMenu(item.navItem) && showNavItem(item.navItem.labelKey)">
          <a (click)="onMenuItemClick(item, $event)" 
            class="non-expanded-link link">
            <ng-container>
              <span [setSVGAttributes]="svgAttributes" *ngIf="item.navItem.svg" [inlineSVG]="item.navItem.svg" class="sideNavIcons">
              </span>
              <mat-icon *ngIf="item.navItem.matIcon" mat-list-icon class="sideNavIcons" tooltip="{{ item.navItem.label }}" placement="right"
                tooltipClass="themeTooltip">{{ item.navItem.matIcon }}</mat-icon>
            </ng-container>
          </a>
        </div>
      </ng-container>
    </ng-template>
  </mat-nav-list>
</div>

<div #topMenu="matMenuTrigger" style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="addMenu">
</div>
<div #schedulesMenu="matMenuTrigger" style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="childMenu" (menuClosed)='menuClosed()'>
</div>
<div #shipmentsMenu="matMenuTrigger" style="visibility: hidden; position: fixed" [style.left]="contextMenuPosition.x"
  [style.top]="contextMenuPosition.y" [matMenuTriggerFor]="childMenu" (menuClosed)='menuClosed()'>
</div>
<mat-menu #addMenu="matMenu" class='add-container-menu' backdropClass="add-shipment">
  <mat-nav-list id="navList">
    <div class="nav-item" [class.expanded]='!isExpanded' *ngIf="showNavItem('add_ocean_shipments')" [class]="userAccessService.accessClass(accessKeys.ADD_OCEAN_SHIPMENT)">
      <button mat-menu-item (click)="addShipments(ShipmentType.INTERMODAL_SHIPMENT)" placement="right"
        [tooltip]="topMenu.menuOpen && userAccessService.getTooltip(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)" tooltipClass="themeTooltip">
        <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.addContainer"></span>
        <span class="pl-16">Add Ocean Shipments</span>
      </button>
    </div>
    <div class="nav-item" *ngIf="showNavItem('add_air_shipments')" [class]="userAccessService.accessClass(accessKeys.ADD_AIR_SHIPMENT)">
      <button mat-menu-item (click)="addShipments(ShipmentType.AIR_SHIPMENT)" placement="right"
        [tooltip]="topMenu.menuOpen && userAccessService.getTooltip(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)" tooltipClass="themeTooltip">
        <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.airShipment"></span>
        <span class="pl-16">Add Air Shipments</span>
      </button>
    </div>
    <div class="nav-item" *ngIf="isTruckShipmentsUploadAllowed && showNavItem('add_road_shipments')" [class]="userAccessService.accessClass(accessKeys.ADD_ROAD_SHIPMENT)">
      <button mat-menu-item (click)="addShipments(ShipmentType.TRUCK_SHIPMENT)" placement="right"
        [tooltip]="topMenu.menuOpen && userAccessService.getTooltip(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)" tooltipClass="themeTooltip">
        <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.roadShipment"></span>
        <span class="pl-16">Add Road Shipments</span>
      </button>
    </div>
    <div class="nav-item" *ngIf="isOLShipmentsUploadAllowed && showNavItem('add_outsourced_logistics')" [class]="userAccessService.accessClass(accessKeys.ADD_OL_SHIPMENT)">
      <button mat-menu-item (click)="addShipments(ShipmentType.OL_SHIPMENT)" placement="right"
        [tooltip]="topMenu.menuOpen && userAccessService.getTooltip(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)" tooltipClass="themeTooltip">
        <span [setSVGAttributes]="olSvgAttributes" [inlineSVG]="SvgMap.olShipment"></span>
        <span class="ol-text-add">Add Outsourced Logistics ID</span>
      </button>
    </div>
    <div class="nav-item" *ngIf="showNavItem('download_csv_template')"
      [class]="userAccessService.accessClass(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)" placement="right"
      [tooltip]="topMenu.menuOpen && userAccessService.getTooltip(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)" tooltipClass="themeTooltip">
      <button mat-menu-item [matMenuTriggerFor]="menuCSV" [matMenuTriggerData]="{file: TemplateFileExtensionTypes.csv}">
        <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.export"></span>
        <span class="pl-16">Download CSV Template</span>
      </button>
    </div>
    <div class="nav-item" *ngIf="showNavItem('download_excel_template')"
      [class]="userAccessService.accessClass(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)" placement="right"
      [tooltip]="topMenu.menuOpen && userAccessService.getTooltip(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)" tooltipClass="themeTooltip">
      <button mat-menu-item [matMenuTriggerFor]="menuCSV" [matMenuTriggerData]="{file: TemplateFileExtensionTypes.xlsx}">
        <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.export"></span>
        <span class="pl-16">Download Excel Template</span>
      </button>
    </div>
    <div class="nav-item" *ngIf="showNavItem('uploads_history')" [class]="userAccessService.accessClass(accessKeys.ADD_SHIPMENT_UPLOAD_HISTORY)">
      <button mat-menu-item (click)="gotoUploadHistory(false, {})" placement="right"
        [tooltip]="topMenu.menuOpen && userAccessService.getTooltip(accessKeys.ADD_SHIPMENT_UPLOAD_HISTORY)" tooltipClass="themeTooltip">
        <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.upload"></span>
        <span class="pl-16">Uploads History</span>
      </button>
    </div>
    <!--<div class="nav-item">
       <button mat-menu-item (click)="globalFileInput.click()" [class]="userAccessService.accessClass(accessKeys.ADD_SHIPMENT_ADD_SHIPMENT)">
        <span [setSVGAttributes]="svgAttributes" [inlineSVG]="SvgMap.addContainer"></span>
        <span class="pl-16">Upload Shipments</span>
      </button>
    </div>-->
  </mat-nav-list>
</mat-menu>
<mat-menu #menuCSV="matMenu" class='add-container-menu'>
  <ng-template matMenuContent let-file="file">
    <mat-nav-list id="navList">
      <div class="nav-item" *ngIf="showNavItemWithParentKey('upload_container_template', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByContainer, file)">
          Upload container # template
        </button>
      </div>
      <div class="nav-item" *ngIf="showNavItemWithParentKey('upload_booking_template', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByBooking, file)">
          Upload booking # template
        </button>
      </div>
      <div class="nav-item" *ngIf="showNavItemWithParentKey('upload_mbl_template', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByMBL, file)">
          Upload MBL # template
        </button>
      </div>
      <div class="nav-item" *ngIf="showNavItemWithParentKey('upload_vessel_template', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByVessel, file)">
          Upload Vessel # template
        </button>
      </div>
      <div class="nav-item" *ngIf="showNavItemWithParentKey('upload_awb_template', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByAir, file)">
          Upload AWB # template
        </button>
      </div>
      <div class="nav-item" *ngIf="isOLShipmentsUploadAllowed && showNavItemWithParentKey('upload_outsourced_logistics_template', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByOL, file)">
          Upload Outsourced Logistics template
        </button>
      </div>
      <div class="nav-item" *ngIf="isTruckShipmentsUploadAllowed && showNavItemWithParentKey('upload_road_template', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByRoad, file)">
          Upload road # template
        </button>
      </div>
      <div class="nav-item" *ngIf="showNavItemWithParentKey('update_shipment', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByBulk, file)">
          Update shipment template
        </button>
      </div>
      <div class="nav-item" *ngIf="isTruckShipmentsUploadAllowed && showNavItemWithParentKey('update_road_shipment', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByRoadUpdate, file)">
          Update road shipment template
        </button>
      </div>
      <div class="nav-item" *ngIf="showNavItemWithParentKey('update_milestone', file)">
        <button mat-menu-item (click)="downloadTemplate(AddContainerByTypes.ByMileStone, file)">
          Update milestone template
        </button>
      </div>
    </mat-nav-list>
  </ng-template>
</mat-menu>

<mat-menu #childMenu="matMenu" class='sub-menu'>
  <mat-nav-list id="navList">
    <ng-container *ngFor="let item of children" >
      <div class="nav-item" [class]="userAccessService.accessClass(item.navItem.accessKey)" [ngClass]="{ 'selected-item': isSelected(item) }"  *ngIf="showMenu(item.navItem) && showNavItem(item.navItem.labelKey)">
        <a (click)="onMenuItemClick(item, $event)" class="link child-container">
          <ng-container>
            <span [setSVGAttributes]="svgAttributes" *ngIf="item.navItem.svg" [inlineSVG]="item.navItem.svg"
              class="sideNavIcons child expanded-span"></span>
            <mat-icon *ngIf="item.navItem.matIcon" mat-list-icon class="sideNavIcons child">{{
              item.navItem.matIcon }}</mat-icon>
            <span class="ml-4 text-uppercase text-bold text child-expand">{{ item.navItem.label }}</span>
          </ng-container>
        </a>
        <mat-divider *ngIf="item.navItem" class="line"></mat-divider>
      </div>
    </ng-container>
  </mat-nav-list>
</mat-menu>