<ng-container *ngIf="logoMode === LogoMode.compact">
  <img class="corp-logo" *ngIf="!loadLogoFailed" src="{{ 'assets/images/corp_logos/' + orgCode + '_square.png' }}"
    (error)="loadLogoFail($event)" alt="logo" />
  <mat-icon svgIcon="site_logo" *ngIf="loadLogoFailed"></mat-icon>
</ng-container>

<ng-container *ngIf="logoMode === LogoMode.wide">
  <img class="corp-logo" *ngIf="!(loadLogoFailed || isOrgLogo)" src="{{ 'assets/images/corp_logos/' + orgCode + '_long.png' }}"
       (error)="loadLogoFail($event)" alt="logo" />
  <ng-container *ngIf="isOrgLogo && isOrgImageLoaded">
    <img [src]="orgImage" class='org-logo' alt="logo" />
  </ng-container>
  <img class="corp-logo" *ngIf="loadLogoFailed && !isOrgLogo" src="assets/cargoflow-new.svg" alt="logo" />
</ng-container>

<ng-container *ngIf="logoMode === LogoMode.full">
  <img class="corp-logo-full" *ngIf="!loadLogoFailed" src="{{ 'assets/images/corp_logos/' + orgCode + '_full.png' }}"
    (error)="loadLogoFail($event)" alt="logo" />
  <img class="corp-logo-full" *ngIf="loadLogoFailed" src="assets/cargoflow-new.svg" alt="logo" />
</ng-container>
